<template>
  <div class="dataTables_paginate paging_simple_numbers float-right">
    <ul class="pagination pagination-rounded mb-0 mt-2">
      <nav aria-label="Simple Pagination" class="mr-2">
        <ul class="pagination">
          <li class="page-item" :class="{'disabled': page <= 1}" @click="() => page > 1 ? page -= 1 : undefined">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          <li v-if="page > 1" class="page-item" @click="() => page > 1 ? page -= 1 : undefined">
            <a class="page-link" href="#">{{ page - 1 }}</a>
          </li>

          <li class="page-item active">
            <a class="page-link" href="#">{{ page }}</a>
          </li>

          <li v-if="hasMorePage" class="page-item" @click="() => hasMorePage ? page = +page + +1 : undefined">
            <a class="page-link" href="#">{{ +page + +1 }}</a>
          </li>

          <li class="page-item" :class="{'disabled': !hasMorePage}" @click="() => hasMorePage ? page = +page + +1 : undefined">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>

      <b-form-input v-model="page" style="width: 50px"></b-form-input>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SimplePagination',
  props: {
    currentPage: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      required: true,
      default: 50,
    },
    hasMorePage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    page(val) {
      if (val) {
        this.$emit('update', val)
      }
    },
  },
  created() {
    if(this.currentPage){
      this.page = this.currentPage
    }
  },
}
</script>